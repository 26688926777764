.footer {
    background-color: var(--background); /* Replace with your brand's purple color code */
    color: var(--text);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: start;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    border-top: 1px solid var(--primary);
  }
  
  .footer-section {
    flex: 1;
    padding: 0 1rem;
    text-align: left;
  }
  
  .footer-section h2, .footer-section h3 {
    margin: 0.5rem 0;
    color: var(--text);
    font-family: 'Montserrat', sans-serif;
font-size: 1.5rem;
  }
  
  .footer-section .facebook-section {
    order: 2;
  }
  
  .footer-section a {
    color: var(--text);
    display: block;
    margin: 0.5rem 0;
    font-family: 'Montserrat', sans-serif;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    color: var(--primary);
    text-decoration: none;
    text-decoration: underline;
  }
  
  .footer-button {
    background-color: var(--primary); /* Replace with your brand's pink color code */
    color: var(--text-secondary) !important;
    width: 150px;
    padding: 0.5rem 1rem;
    border-radius: 1.2rem;
    border: none;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    transition: background-color 0.3s;
    font-family: 'Montserrat', sans-serif;
    margin: 20px;

  }
  
  .footer-button:hover {
    background-color: var(--secondary); /* Darker shade for the hover state */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
    }
  
    .footer-section {
      margin-bottom: 1rem;
    }
  
    .footer-section:last-child {
      margin-bottom: 0;
    }
  }
  