/* LoginPage.css */
.login-container {
    background-color: var(--background); /* Background color similar to the register page */
    width: 100%; /* Full width to center the form */
    max-width: 575px; /* Maximum width of the form */
    margin: auto; /* Center the form with auto margins */
    padding: 2rem; /* Padding inside the form */
    border-radius: 10px; /* Rounded corners for the form */
    font-family: 'Arial', sans-serif; /* Font family to match */
}

/* Mobile */
@media only screen and (max-width: 645px) {
    .emojis {
        flex-wrap: wrap;
    }

    .emojis .emoji {
    }
}

.emojis .emoji {
    padding: 10px;
    font-size: calc(min((100vw / 4) - 24px, 100px));
    background: none;
    border: none;
    cursor: pointer;
}

.pinpad {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
}

.emojis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4px
}

.login-container h2 {
    color: #604a66; /* Title color to match the register page */
    text-align: center; /* Center the title */
    margin-bottom: 20px; /* Space below the title */
    font-size: 2.5rem; /* Increased font size for visibility */
}

.login-container input[type="email"],
.login-container input[type="password"] {
    width: 100%; /* Full width minus padding */
    padding: 10px; /* Padding inside the inputs */
    margin-bottom: 15px; /* Space between the inputs */
    border: 1px solid #ccc; /* Border color to match */
    border-radius: 5px; /* Rounded corners for inputs */
    font-size: 1rem; /* Font size for readability */
}

.login-container label {
    color: var(--text-secondary); /* Label color to match */
    margin-bottom: 8px; /* Space below the label */
    font-size: 1rem; /* Font size to match */
    display: block; /* Ensure labels are on their own line */
}

.login-container button[type="submit"] {
    width: 100%; /* Full width button */
    padding: 15px; /* Comfortable padding inside the button */
    background-color: #6a5870; /* Button background color to match */
    color: white; /* Text color for contrast */
    border: none; /* No border for simplicity */
    border-radius: 5px; /* Rounded corners for aesthetics */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 16px; /* Font size for visibility */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.login-container button[type="submit"]:hover {
    background-color: #5d4a62; /* Slightly darker shade on hover */
}

/* Error message styling */
.error-message {
    color: red; /* Red color for errors */
    text-align: center; /* Center the error message */
    margin-top: 20px; /* Space above the error message */
}

/* Focus state for inputs to match the register page */
.login-container input[type="email"]:focus,
.login-container input[type="password"]:focus {
    outline: none;
    border-color: #6a5870; /* Focus border color to match */
}
