/* ChatPage.css - Color Scheme Matched */

body, h1, h2, h3, p, ul, ol, nav, figure, figcaption, button {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif; /* Font consistency */
  }
  
  .chat-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 600px;
      margin: 0 auto;
      border: 1px solid #ddd;
      overflow: hidden;
  }
  
  .messages-container {
      flex-grow: 1;
      overflow-y: auto;
      background-color: #f9f9f9; /* Light background for the messages area */
      padding: 20px;
      display: flex;
      gap: 10px;
      flex-direction: column;
  }
  
  .message-container {
      display: flex;
      flex-direction: column;
  }

  .message-container.right {
    justify-content: right;
  }

  .message-container.left  {
    justify-content: left;
  }

  .message-container.right > .sender-name {
    text-align: right;
  }

  .message-container.left > .sender-name  {
    text-align: left;
  }

  .message-container.right > .text-message  {
    align-self: flex-end;
    background-color: grey;
  }

  .message-container.left > .text-message  {
    align-self: flex-start;
    background-color: #59475f; /* Darker purple for text messages, consistent with AdminDashboard */
  }

  .message-container.left > .audio-message  {
    align-self: flex-start;
  }

  .message-container.right > .audio-message  {
    align-self: flex-end;
  }
  
  .sender-name {
      font-size: 0.8rem;
      color: #423048; /* Dark purple text for consistency */
  }
  
  .message {
      padding: 10px;
      border-radius: 12px;
      max-width: 75%;
  }
  
  .text-message {
      color: white;
  }
  
  .input-container {
      padding: 4px !important;
      background-color: #d5cbb7; /* Light background for input area, matching AdminDashboard */
      display: flex;
      gap: 10px;
      border-top: 1px solid #ddd;
      max-width: none;
      justify-content: space-around;
  }

  .message-input {
      flex-grow: 1;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 12px;
      margin: 0 !important;
  }
  
  button {
      padding: 10px 15px;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      font-weight: bold;
  }
  
  .send-button {
      background-color: #59475f; /* Green button for send */
      color: white;
      border-radius: 12px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .send-button > img {
    margin: 0;
    filter: invert();
  }
  
  .record-button {
      background-color:  #deafa1; /* Red button for recording */
      color: white;
      border-radius: 12px;
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .record-button > img {
    margin: 0;
    filter: invert();
  }

  .button-logo {
      height: 25px;
  }



  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#root, .App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    flex: 0 0 auto; /* Header should take up only the space it needs */
}

.footer {
    flex: 0 0 auto; /* Footer should take up only the space it needs */
}

.main-content {
    flex: 1 1 auto; /* Main content should take up the remaining space */
    display: flex;
    flex-direction: column;
}

.employee-dashboard, .admin-dashboard {
    flex: 1 1 auto; /* Employee dashboard should take up the remaining space */
    display: flex;
    flex-direction: column;
}

.employee-content {
    flex: 1 1 auto; /* Employee content should take up the remaining space */
    display: flex;
    flex-direction: column;
}

.chat-container {
    flex: 1 1 auto; /* Chat container should take up the remaining space */
    overflow: auto; /* Ensure that chat content scrolls if necessary */
}