/* Adjusted UsersList.css to match Calendar.css style */

.table-container {
    max-width: 1000px;
    margin: 40px auto;
    overflow-x: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    font-family: 'Montserrat';
    position: relative;
}

table {
    width: 100%;
    border-collapse: collapse;
    position: relative;
}

@media only screen and (max-width: 645px) {
    thead {
        top: 75px !important;
    }
}

@media only screen and (max-width: 1175px) and (min-width: 646px) {
    thead {
        top: 80px !important;
    }
}

thead {
    position: sticky;
    top: 0;
    opacity: 1;
    z-index: 100;
    background-color: #f2f2f2;
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

tr:hover:not(:first-child) {
    background-color: #f5f5f5;
}

th {
    height: 50px;
    color: #333;
}

.table-container .d-button {
    background-color: #6a5870; /* Green */
    color: white;
    padding: 15px 25px; /* Increased padding for larger button */
    margin: 5px 0;
    border: none;
    border-radius: 4px; /* You can adjust this if you want more rounded corners */
    cursor: pointer;
    transition: background-color 0.2s ease-in-out; /* Smooth transition for hover effect */
    font-family: 'Montserrat';
    font-size: 1rem; 
    color: var(--champagne);
}


.table-container .d-button:hover {
    background-color: #deafa1; /* Darker shade for hover, matching Calendar.css */
}

h2 {
    font-size: 24px; /* Adjusted to match Calendar.css title size */
    color: #333;
    margin-bottom: 20px; /* Added spacing to match the header toolbar margin in Calendar.css */
}
