@media only screen and (max-width: 850px) {
    .menu-item-details {
        flex-direction: column;
    }
    .menu-item {
        flex-direction: column;
    }
    input[type='file'] {
        display: none;
    }
}

.menu-builder {
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.menu-builder form {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: none;
}

.menu-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 12px;
    justify-content: space-between;
    border-bottom: 1px solid #5bc0de;
    gap: 12px;
}

.menu-item-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menu-item input[type="text"], 
.menu-item input[type="file"] {
    padding: 5px;
    flex: 1;
    margin-bottom: 0;
}

.menu-item img {
    width: 100px;
}

.menu-list {
    max-height: 40vh;
    overflow-y: scroll;
}

.menu-item button {
    padding: 5px 10px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.menu-item button:hover {
    background-color: #c9302c;
}

.item-name-price {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
}

button[type="button"] {
    background-color: #5bc0de;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

button[type="button"]:hover {
    background-color: #31b0d5;
}

button[type="submit"] {
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #4cae4c;
}
