:root {
  --black: #000000;
  --olive-drab: #647249;
  --purple-gray: #6a5870;
  --pale-pink: #deafa1;
  --terra-cotta: #db7859;
  --champagne: #eeeeee;
  --background: #eeeeee;
  --primary: #595067;
  --secondary: #2F2441;
  --text: #272727;
  --text-secondary: white;
}

/* Tablet */
@media only screen and (max-width: 1450px) {
  .home-header {
    flex-direction: column;
    top: -78px !important;
    gap: 4px !important;
  }
}

/* Mobile */
@media only screen and (max-width: 858px) {
  .home-header {
    width: 100vw;
    height: 75px;
    display: block;
    top: 0px !important;
  }

  .home-header > .logo {
    display: none !important;
    width: 100%;
  }

  .home-header > .menu-items {
    gap: 12px !important;
  }

  .home-header > .menu-items, .home-header > .menu-items > a, .home-header > .menu-items > a > button, .home-header > .menu-items > button, .home-header > .menu-items > a {
    font-size: 0.6rem;
    height: 50px !important;
    font-size: 0 !important;
  }

  .home-header img {
    height: 24px !important;
    width: 24px !important;
    margin: 0 !important;
  }
}

.App {
  position: relative;
  font-family: 'Arial', sans-serif;
  color: var(--text);
  background-color: var(--background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-header a h1 {
  text-decoration: underline;
}

.home-footer {
  background-color: var(--primary); /* Purple background for footer */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  padding: 1rem; /* Add padding for spacing */
  text-align: center; /* Center text */
}

.home-footer p {
  color: var(--text); /* Champagne color text */
  margin-right: 200px; /* Add some space between the text and the button */
  font-size: 1.2rem; /* Adjust the size as needed */
  font-family: 'Montserrat', sans-serif; /* Font styling */
}

.contact-button {
  background-color: var(--primary); /* Use the donate button color */
  color: white; /* Text color */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  padding: 10px 20px; /* Padding inside the button */
  font-size: 1.1rem; /* Font size */
  border: none; /* No border */
  cursor: pointer; /* Cursor to pointer */
  transition: background-color 0.3s ease; /* Transition for hover effect */
  font-family: 'Montserrat', sans-serif; /* Font styling */
}

.contact-button:hover {
  background-color: var(--secondary); /* Darker shade for hover */
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.contact-icon {
  margin-right: 8px;
  height: 25px; /* Example size */
  vertical-align: middle; /* Aligns icon with text */
}

.donate-icon {
  margin-right: 8px;
  height: 30px; /* Example size */
  vertical-align: middle; /* Aligns icon with text */
}

.login-icon2 {
  margin-right: 8px;
  height: 30px; /* Example size */
  vertical-align: middle; /* Aligns icon with text */
}

.logout-icon2 {
  margin-right: 8px;
  height: 30px; /* Example size */
  vertical-align: middle; /* Aligns icon with text */
  filter: invert();
}

.sign-icon {
  margin-right: 8px;
  height: 25px; /* Example size */
  vertical-align: middle; /* Aligns icon with text */
}


.header-title {
  margin-left: 0px; /* Adjust as necessary for spacing between logo and text */
  /* ... other styles ... */
  font-size: 1.7rem;
  margin-top: 7px;
  font-family: 'Montserrat', sans-serif;
}

.home-header > .menu-items > a, .home-header > .menu-items > button, .home-header > .menu-items > a > button, .home-header > .menu-items > a {
  height: 64px;
}

.credit {
  font-size: 12px;
}

.home-header {
  gap: 24px;
}

.home-header > .menu-items {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
}

.home-header {
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.home-header > .logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 12px;
}

.text-to-speech-toggle {
  display: flex;
  align-items: center;
  background-color: var(--primary); /* Or any other background color you prefer */
  color: var(--text-secondary); /* Or any other text color you prefer */
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px; /* Optional: for rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.text-to-speech-toggle:hover {
  background-color: var(--secondary); /* Or any other hover color you prefer */
  color: var(--text-secondary);
}

.text-to-speech-toggle img {
  width: 30px; /* Smaller icon size */
  height: 37.5px; /* Smaller icon size */
  margin-right: 8px; /* Space between icon and text */
}
