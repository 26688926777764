@media only screen and (max-width: 1200px) {
    .container {
        flex-direction: column;
    }
}

.container {
    margin: auto;
    display: flex;
    justify-content: space-around; /* Centers the squares with space around them */
    align-items: center; /* Aligns the squares vertically */
}

.container > a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.square {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers text horizontally */
    align-items: center; /* Centers text vertically */
    font-size: 2rem; /* Increases the text size */
    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
    border-radius: 10px; /* Adds rounded edges to the squares */
}

#games, #chat {
    /*background-color: lightblue; /* Differentiates the Games square */
    display: flex;
    justify-content: center; /* Centers the image horizontally */
    align-items: center; /* Centers the image vertically */
    position: relative; /* Enables positioning of child elements */
    max-width: 500px;
    max-height: 500px;
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);
}

#games h1, #chat h1 {
    z-index: 1; /* Sets a higher stacking order for the h1 element */
    font-family: 'Montserrat', sans-serif;
    color: rgb(72, 63, 81);
    font-size: 3rem;
}

#games img, #chat img {
    width: 100%; /* Ensures the image fills the Games div */
    height: 100%; /* Ensures the image fills the Games div */
    object-fit: cover; /* Ensures the image fills the div without distortion */
}
    
a {
    /*color: inherit; /* Keeps the hyperlink text color unchanged */
    color: #000;
    
}