@media only screen and (max-width: 1060px) {
    .menu-display {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .menu-display > .item {
        flex-direction: row !important;
        justify-content: space-around !important;
    }
}

.menu-display-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.menu-display {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px; /* Adjust the gap between items as needed */
}

.menu-display > .item {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the content within each item */
    padding: 16px; /* Add padding if needed for better spacing */
    border-radius: 8px; /* Optional: Add border radius to each item */
    justify-content: flex-start;
    background: #f9f9f9;
}

.menu-display > .item  > .menu-item-name {
    font-size: 16px; /* Adjust the font size as needed */
}

.menu-display > .item > .menu-item-price {
    font-size: 14px; /* Adjust the font size as needed */
    color: #555; /* Optional: Add color to the price text */
}

.menu-display > .item > .menu-item-img {
    margin-top: 8px; /* Adjust the margin as needed */
    width: 100px; /* Ensure the image width is consistent */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 12px;
}
